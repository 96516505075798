// thiscovery-survey-manuals
// super-simple one-page-multi-page

const version = require("../../package.json").version;
const debug = require('debug')("app.js");
localStorage.setItem("debug", "*,-socket.io-client:*,-engine.io-client:*,-socket.io-parser");
const _ = require('lodash');
import Case from 'case';

// const manual = require("./manual.json").map(item=>{
//     return _.assign(item,{
//         pageid : Case.kebab(item.page)
//     })
// });

const firstItem = require("./manual.json")[0].page;

debug(`THISCOVERY VIEWS MANUAL - Version ${version}`);

// stylesheet

import '../css/main.css';

// vue imports

import { createApp } from "vue";
import RootComp from './components/root.vue';
import Page from './components/PageView.vue';
import { createRouter, createWebHashHistory } from 'vue-router';

// build router from manual

const routes = [
    {
        path: "/",
        redirect : "/" + Case.kebab(firstItem)
    },{
        path: "/home",
        redirect : "/" + Case.kebab(firstItem)
    },{
        path : "/:pageid",
        component : Page,
        name : "Page",
        props : true
    }
];

const router = createRouter({
    history : createWebHashHistory(),
    routes,
    scrollBehavior (to) {
        if (to.hash) {
            return {
              el: to.hash,
              behavior : "smooth"
            }
          }
    },

});

router.beforeEach((to)=>{
    if (!!to.hash) return;
    window.scrollTo(0,0);
});

router.afterEach((to)=>{
    window.document.title = `Thiscovery VIEWS Documentation - ${Case.title(_.trim(to.path,"/"))}`;
});

const initialise = async function() {
    // no real need to be async here but hey
    const app = createApp(RootComp);
    app.use(router);
    app.component("RenderContent",{
        props: ['vnodes'],
        render:function(){
            return this.vnodes;
        }
    });
    app.mount("#app");
};
initialise();