<script>

const debug = require('debug')('root.vue');
const _ = require('lodash');
import Case from 'case';

import NavBar from "./NavBar.vue";

const manual = require("../manual.json").map(item=>{
    return _.assign(item,{
        pageid : Case.kebab(item.page)
    })
});

export default {
    components : {
        NavBar
    },
    data : function(){
        return {
            manual,
            mobNavOpen : false,
            closeTimeout : null
        }
    },
    methods : {
        openMobNav() {
            this.mobNavOpen = !this.mobNavOpen;
            clearTimeout(this.closeTimeout);
            this.closeTimeout = setTimeout(()=>{
                this.mobNavOpen = false;
            },5000);
        }
    }
}
</script>

<template>
    <div>
        <div class="header bg-thisco-yellow text-white h-[5rem] sm:h-[6rem] min-w-full thisco-logo bg-[4rem_50%] md:bg-[1rem_50%] shadow-sm">
            <header>
                <div class="header-content">
                    <div @click="openMobNav" class="mobile-nav-button md:hidden w-[3rem] h-[5rem] sm:h-[6rem] flex flex-wrap flex-col items-center justify-center transition-transform hover:scale-110 active:scale-[.9] focus:scale-110 focus:outline-none cursor-pointer">
                        <div class="inline-block bg-thisco-red rounded-[999px] h-1 w-6"></div>
                        <div class="inline-block bg-thisco-red rounded-[999px] h-1 w-6 my-1"></div>
                        <div class="inline-block bg-thisco-red rounded-[999px] h-1 w-6"></div>
                    </div>
                </div>
            </header>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-[10rem,1fr] xl:grid-cols-[15rem,1fr]">
            <div class="z-10 nav min-h-[100vh] h-[100vh] absolute w-full md:relative pt-4 xl:pt-10 bg-thisco-offwhite -translate-x-[100%] md:translate-x-0 transition" @click="openMobNav" :class="{'translate-x-0':mobNavOpen}">
                <nav>
                    <NavBar></NavBar>
                </nav>
            </div>
            <div class="main p-4 xl:pt-10">
                <main>
                    <router-view :key="$route"></router-view>
                </main>
            </div>
        </div>
        
    </div>
</template>