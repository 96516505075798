{
    "name": "thiscovery-views-manual",
    "version": "1.0.4",
    "description": "",
    "main": "index.js",
    "scripts": {
        "build": "run-s build:manual build:js",
        "build:manual": "node ./build_man.js",
        "build:js": "parcel build ./src/js/app.js",
        "bsync": "browser-sync ./ -s -f ./dist/**.*",
        "dev": "run-p dev:js watch:manual",
        "dev:js": "parcel ./src/js/app.js --no-hmr",
        "watch:manual" : "chokidar --verbose \"./config.json,./src/md_pages/**.*\" -s \"npm run build:manual\""
    },
    "author": "",
    "license": "ISC",
    "browserslist": [
        "> 0.5% in GB"
    ],
    "targets": {
        "default": {
            "distDir": "./dist"
        }
    },
    "dependencies": {
        "@tailwindcss/typography": "^0.5.2",
        "case": "^1.6.3",
        "date-fns": "^2.28.0",
        "debug": "^4.3.4",
        "highlight.js": "^11.5.1",
        "lodash": "^4.17.21",
        "marked": "^4.0.14",
        "tailwindcss": "^3.0.24",
        "vue": "^3.2.33",
        "vue-router": "^4.0.14"
    },
    "devDependencies": {
        "@parcel/transformer-vue": "^2.5.0",
        "autoprefixer": "^10.4.5",
        "browserslist": "^4.20.3",
        "chalk": "^3.0.0",
        "chokidar": "^3.5.3",
        "chokidar-cli": "^3.0.0",
        "markdown-it": "^13.0.0",
        "markdown-it-anchor": "^8.6.2",
        "markdown-it-highlightjs": "^4.0.1",
        "npm-run-all": "^4.1.5",
        "parcel": "^2.5.0",
        "postcss": "^8.4.12",
        "postcss-cli": "^9.1.0",
        "process": "^0.11.10",
        "sass": "^1.50.1"
    }
}
