<script>
import Case from 'case';
const pages = require("../../../config.json").pages;
const _ = require('lodash');
const debug = require('debug')('NavBar.vue');
const trimHyphen = (i)=>_.trim(i,"-");

export default {
    computed : {
        pagelist : function(){
            return _.toPairs(pages).map(pair=>[pair[0],Case.kebab(pair[1])]);
        }
    },
    methods : {
        active(pageid="") {
            return _.trim(this.$route.path,"/") == pageid;
        },
        trimHyphen
    }
}
</script>

<template>
    <div>
        <ul class="px-4">
            <li v-for="page in pagelist" :key="page.$index" class="mb-4">
                <router-link v-if="!page[0].includes('divider')" :to="`/${page[1]}`" :class="{'text-thisco-red':active(page[1]),'text-[.9em] pl-4':page[0].charAt(0)=='-'}" taborder="0">{{trimHyphen(page[0])}}</router-link>
                <hr class="w-3/4 ml-[12%] mb-4" v-else>
            </li>
        </ul>
    </div>
</template>