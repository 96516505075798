<script>

const debug = require('debug')('PageView.vue');
import 'highlight.js/styles/atom-one-dark.css'
import hljs from 'highlight.js';
hljs.configure({
    languages : ["HTML","JavaScript"]
})
// import 'highlight.js/lib/common';
// hljs = require('highlight.js');
import { format } from 'date-fns';
const _ = require('lodash');
const version = require('../../../package.json').version;
const stylesheet_version = require('../../../config.json').stylesheet_version;
const js_version = require('../../../config.json').js_version;
const trimHyphen = (i)=>_.trim(i,"-");

const EXT_LINK = `https://thiscovery-skin.s3.eu-west-1.amazonaws.com/dist/bundle.${stylesheet_version}`;
const SHEET_LINK = EXT_LINK + ".css";
const JS_LINK = EXT_LINK + ".js";

export default {
    computed : {
        modified : function(){
            return format(new Date(_.get(this.page,"modified",null)),"eeee do MMMM yyyy")
        },
        nextPage : function(){
            const thisIndex = this.$root.manual.findIndex(({pageid})=>this.$route.path == "/"+pageid);
            return thisIndex+1 == this.$root.manual.length ? false : this.$root.manual[thisIndex+1];
        },
        page : function(){
            return this.$root.manual.find(({pageid})=>this.$route.path == "/"+pageid)
        },
        prevPage : function(){
            const thisIndex = this.$root.manual.findIndex(({pageid})=>this.$route.path == "/"+pageid);
            return thisIndex == 0 ? false : this.$root.manual[thisIndex-1];
        },
        subnav : function(){
            let subs = [];
            const parsed = new DOMParser().parseFromString(this.page.html,"text/html");
            const headings = Array.from(parsed.querySelectorAll("h2,h3"));
            return headings.map(h=>{
                return {
                    link : h.getAttribute("id"),
                    label : h.innerText,
                    depth : h.tagName == "H2" ? 2 : 3
                }
            });
        }
    },
    data : function(){
        return {
            pagecontent : "Hello",
            version
        }
    },
    directives : {
        highlightme : {
            mounted(el){
                hljs.highlightAll();
            }
        },
        thiscopreview : {
            mounted(el){
                const previewContent = el.getElementsByClassName("thisco-survey");
                if (!previewContent[0]) {
                    debug("No preview content");
                    return;
                }
                // create iFrame
                const frame = document.createElement("iframe");
                frame.setAttribute("scrolling","no");
                frame.classList.add("thisco-preview-frame","active");
                const bodyHtml = previewContent[0].innerHTML;
                const frameHtml = `<body><div class="thisco-survey" style="visibility:hidden;">${bodyHtml}</div></body>`;
                el.closest("div").parentNode.appendChild(frame);

                // add frame control
                const frameControl = document.createElement("div");
                frameControl.innerText= "HTML";
                frameControl.classList.add("thisco-preview-control","active");
                el.closest("div").parentNode.appendChild(frameControl);

                // add frame control
                const preControl = document.createElement("div");
                preControl.innerText= "Preview";
                preControl.classList.add("thisco-preview-code-control");
                el.closest("div").parentNode.appendChild(preControl);

                // add code preview
                const codePreview = document.createElement("div");
                codePreview.className = "thisco-preview-code prose prose-pre:p-0 prose-pre:whitespace-pre-wrap prose-pre:text-xs";
                codePreview.innerHTML = `<pre><code class='hljs language-HTML'>${bodyHtml.replace(/</g,"&lt;")}</code></pre>`
                el.closest("div").parentNode.appendChild(codePreview);
                window.requestAnimationFrame(()=>{
                    hljs.highlightAll();
                });

                const toggleActive = ()=>{
                    [frame,frameControl,codePreview,preControl].forEach(el=>el.classList.toggle('active'));
                };
                frameControl.addEventListener('click',toggleActive);
                preControl.addEventListener('click',toggleActive);

                // fill frame
                const fdoc = frame.contentWindow.document;
                fdoc.open();                
                fdoc.write(frameHtml);
                fdoc.body.setAttribute("style","color:white;font-family:sans-serif;");

                const flink = fdoc.createElement("link");
                flink.setAttribute("rel","stylesheet");
                flink.setAttribute("href",SHEET_LINK);
                fdoc.head.appendChild(flink);

                const fscript = fdoc.createElement("script");
                fscript.setAttribute("src",JS_LINK);
                window.setTimeout(()=>{
                    fdoc.body.appendChild(fscript); 
                    fdoc.close();
                },500);

                const sizeFrame = _.debounce(()=>{
                    const h = fdoc.body.scrollHeight;
                    const w = fdoc.body.offsetWidth;
                    frame.setAttribute("style",`aspect-ratio: ${w} / ${h+50}`);
                    const survey = fdoc.getElementsByClassName("thisco-survey")[0];
                    survey.setAttribute("style",`visibility:visible`);
                },500);

                // // set frame resizing to min height
                window.requestAnimationFrame(sizeFrame);
                window.document.addEventListener("resize",sizeFrame);

                // remove original
                previewContent[0].remove();
            }
        },
        numbering : {
            mounted(el) {
                Array.from(el.querySelectorAll("ol ol")).forEach(ol=>{
                    ol.setAttribute("type","a");
                });
                Array.from(el.querySelectorAll("ol ol ol")).forEach(ol=>{
                    ol.setAttribute("type","i");
                });
            }
        }
    },
    methods : {
        trimHyphen
    }
}
</script>

<template>
    <div class="grid grid-cols-[1fr] lg:grid-cols-[1fr_18rem] xl:grid-cols-[1fr_18rem] gap-6">
        <div class="prose prose-img:m-auto prose-code:codefix prose-pre:p-0 prose-pre:whitespace-pre-wrap prose-pre:text-xs prose-a:text-thisco-red" v-highlightme="">
            <a id="top" class="opacity-0"></a>
            <span v-html="page.html" v-thiscopreview v-numbering class="relative"></span>
        </div>
        <p class="mt-8 pb-2 block col-span-1 col-start-1 justify-self-stretch">
            <router-link :to="`/${prevPage.pageid}`" v-if="!!prevPage" class="text-thisco-darkgrey text-sm float-left no-underline">&larr;&nbsp;{{trimHyphen(prevPage.pagename)}}</router-link>
            <router-link :to="`/${nextPage.pageid}`" v-if="!!nextPage" class="text-thisco-darkgrey text-sm float-right no-underline">{{trimHyphen(nextPage.pagename)}}&nbsp;&rarr;</router-link>
        </p>
        <hr class="col-span-2 col-start-1" />
        <p class="w-full text-gray-300 italic text-xs col-span-2">Page last modified: {{modified}} <span class="version float-right">{{version}}</span> </p> 
        <div class="sub-nav relative hidden xl:block col-start-2 row-start-1 justify-self-start">
            <ul class="text-sm fixed pr-6">
                <li class="mb-4">              
                    <router-link to="#top">&uarr;&nbsp;Top</router-link>
                </li>
                <li v-for="sub in subnav" :key="sub.link" class="mb-2" :class="{'text-xs pl-4':sub.depth == 3}">
                    <router-link :to="`#${sub.link}`">{{sub.label}}</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>